/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app) => {

		/*
		|
		| Constants
		|-----------
		*/
		const
			$postsContainer = $('.loaded-posts')
		;


		/*
		|
		| Ajax Sample
		|--------------
		*/
		$('[data-ajax-url]').on('click', function(e){


      const parentContainer = $(this).closest('.loaded-posts')
      let offset = parentContainer.find('.card-recette').length;
      let url = $(this).data('ajax-url') + '/' + offset;

      e.preventDefault();

			$.ajax({
				url: url,
				type: 'GET',
				dataType: 'json',
				success: (response, status) => {
          // console.log(response)
					$postsContainer.append(response);
				},
				error: (response, status, error) => {
					console.log(response, status, error);
				}
			})
		});
	}
}

