/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($);
import Swiper from 'swiper/js/swiper.min';
import { TweenMax } from "gsap/TweenMax";
import SplitText from "@lib/gsap-pro/SplitText";
// import ScrollTo from "gsap/ScrollToPlugin";
import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap.js';
import 'scrollmagic/scrollmagic/uncompressed/plugins/jquery.ScrollMagic.js';
// import 'scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';
import ScrollMagic from 'scrollmagic';
import DrawSVGPlugin from "@lib/gsap-pro/DrawSVGPlugin";
import Plyr from 'plyr';
// import barba from '@barba/core'

/*
|
| Importing Components
|-----------------------
*/
import LocomotiveScroll from '@components/loco.js';
import CookieManager from '@components/cookie-manager';
// import customGoogleMap from '@components/custom-google-map.js';
import MaterializeForm from '@components/materialize-form.js';
import AjaxZone from '@components/ajax-zone.js';
import Menu from '@components/menu.js';

/*
|
| Importing Utils
|-------------------
*/
import Router from '@utils/router.js';

/*
|
| Importing App files
|----------------------
*/
import * as app from '@components/global.js';
import main from '@pages/main.js';
import animations from '@pages/animations.js';
import contact from '@pages/contact.js';
import home from '@pages/home.js';
import about from '@pages/about.js';
import products from '@pages/products.js';
import product from '@pages/single-product.js';
import recipe from '@pages/single-recipe.js';
import recipes from '@pages/recipes.js';


/*
|
| Routing
|----------
*/
const routes = new Router([
{
    'file': animations,
    'dependencies': [app, SplitText, LocomotiveScroll, ScrollMagic, Menu]
},
{
  'file': main,
  'dependencies': [app, CookieManager, Plyr]
},
{
    'file': contact,
    'dependencies': [app, MaterializeForm],
    'resolve': '#page-contact'
},
{
    'file': home,
    'dependencies': [app],
    'resolve': '#home'
},
{
    'file': about,
    'dependencies': [app, Plyr],
    'resolve': '#page-about'
},
{
    'file': products,
    'dependencies': [app, AjaxZone, ScrollMagic],
    'resolve': '#page-produits-archive'
},
{
    'file': product,
    'dependencies': [app, Plyr, Swiper],
    'resolve': '#page-produits-single'
},
{
    'file': recipe,
    'dependencies': [app, Plyr],
    'resolve': '#page-recettes-single'
},
{
    'file': recipes,
    'dependencies': [app],
    'resolve': '#page-recettes-archive'
}
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

