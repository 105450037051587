/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Plyr) => {


        const video = $('#video-src');
        const yt = $('#player')


        if (yt.length) {
            $('.sound').on('click', () => {
                if ($('.sound').hasClass('off')) {
                    $('.sound').removeClass('off')
                    player.unMute()
                    player.setVolume(100)
                } else {
                    $('.sound').addClass('off')
                    player.setVolume(0)
                    player.mute()
                }
            })
        } else if (video.length) {
            $('.sound').on('click', () => {
                if (video.prop('muted')) {
                    $('.sound').removeClass('off')
                    video.prop('muted', false)
                } else {
                    $('.sound').addClass('off')
                    video.prop('muted', true)
                }
            })
        }


        function youtube_parser(url) {
            var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
            var match = url.match(regExp);
            return (match && match[7].length == 11) ? match[7] : false;
        }



        /*
        |
        | IziModal
        |-----------
        */
        const url1 = $("#modal-iframe2").attr('data-izimodal-iframeurl')
        $("#modal-iframe2").attr('data-izimodal-iframeurl', 'https://www.youtube.com/embed/' + url1)

        $("#modal-iframe2").iziModal({
            history: false,
            iframe: true,
            fullscreen: true,
            header: false,
            width: 800,
            iframeHeight: 500,
            radius: 0,
            loop: true,
            bottom: null,
            borderBottom: false,
            overlayColor: 'rgba(0, 0, 0, 0.95)',
        });


        if ($('.mkx-modal-loop').length) {

            $('.mkx-modal-loop').each(function() {
                const url = $(this).attr('data-izimodal-iframeurl')
                $(this).attr('data-izimodal-iframeurl', 'https://www.youtube.com/embed/' + youtube_parser(url) + '?autoplay=1')
            })

            $('.mkx-modal-loop').iziModal({
                history: false,
                iframe: true,
                fullscreen: true,
                header: false,
                width: 800,
                iframeHeight: 500,
                radius: 0,
                loop: true,
                bottom: null,
                borderBottom: false,
                overlayColor: 'rgba(0, 0, 0, 0.95)',
            });

            $('.mkx-modal-loop').iziModal('setHeader', false);
        }

            const player3 = new Plyr('#player3', {});
            player3.poster = $('.video-plyr').attr('data-poster')
    }
}

