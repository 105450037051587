/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, CookieManager, Plyr) => {
		/*
		|
		| Constants
		|-----------
		*/
        const
            $body         = $('body'),
            $cookieBanner = $('.cookie-banner'),
            $cookieClose  = $cookieBanner.find('.item-close'),
            $cookieAccept = $cookieBanner.find('.item-accept'),
            $cookieRefuse = $cookieBanner.find('.item-refuse'),
            $fakeLinks    = $('.fake-link')
		;

        $.each( $fakeLinks, (index, element) => {

            let dataHref = $( element ).data('href')

            $( element ).on('click', (e) => {

                e.preventDefault();
                window.open( dataHref, '_blank' )

            })

        })

        /*
		|
		| Cookie Manager
		|-----------------
		*/
        // new CookieManager($cookieBanner, {
        //     name: 'adveris_cookie_use',
        //     duration: 30,
        //     buttons: {
        //         accept: $cookieAccept,
        //         refuse: $cookieRefuse,
        //         close: $cookieClose
        //     },
        //     onAccept: () => {
        //         console.log('accepted')
        //     },
        //     onRefuse: () => {
        //         console.log('refused')
        //     }
        // });

        // $body.on('loaderEnd', () => console.log('ended'))

        /*
        |
        | Cookie Manager
        |-----------------
        */
        var t = setInterval(getSocialData, 1000);

        function getSocialData() {
            if ($('.sbi_item').length > 0) {
                let $example = $('.instafeed-row li');

                $('.sbi_item').each(function(key) {
                    let article = $(this),
                        link = article.find('.sbi_photo').attr('href'),
                        image = article.find('img').attr('src');

                    let $item = $example.clone();
                    $item.removeClass('d-none');
                    // $item.find('image').attr('xlink:href', image);
                    $item.find('.img-insta').attr('style', 'background-image: url('+ image +')');

                    $('.instafeed-row').append($item);
                });

                $example.remove();

                if (window.locomotive) {
                    window.locomotive.update();
                }

                clearInterval(t);
            }
        }


        const player3 = new Plyr('#playerSingle', {});
        player3.poster = $('.video-plyr').attr('data-poster')


	}
}

