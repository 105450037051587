/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, MaterializeForm) => {
        /*
        |
        | Constants
        |------------
        */
        const
            $map = $('#map'),
            $contactForm = $('.contact-form')
        ;


        /*
		|
		| initGoogleMap
		|----------------
		|
		*/
        // $.each($map, function () {
        //     var $map = $(this);

        //     var map = new customGoogleMap($map, {
        //         'markers': $map.find('.marker'),
        //         'zoom': 16
        //     });
        // });


        /*
		|
		| Materialize form
		|-------------------
		|
        */
        new MaterializeForm($contactForm, {
            selectors: {
                group: '.gfield',
                label: '.gfield_label',
                input: '.ginput_container_text input, .ginput_container_email input'
            },
            labelEffect: {
                duration: 0.3,
                ease: Power2.easeOut
            }
        });

        const items = document.querySelectorAll(".accordion a");

        function toggleAccordion(){
          this.classList.toggle('active');
          this.nextElementSibling.classList.toggle('active');
        }

        items.forEach(item => item.addEventListener('click', toggleAccordion));
    }
}

