/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (app, SplitText, LocomotiveScroll, ScrollMagic, Menu) => {

    /*
    |
    | Constants
    |-----------
    |
    */
    const
        $window             = $(window),
        $body               = $('body'),
        $left_title         = $('.js-left'),
        $right_title        = $('.js-right'),
        $inside_title       = $('.js-inside'),
        $inside_title_alone = $('.js-inside-alone'),
        $inside_p           = $('.js-p'),
        $menuWrapper        = $('#mobile-menu'),
        $menuButton         = $('#header .btn-menu'),
        $menuBars           = $menuButton.find('.item-burger > span'),
        $menuClose          = $menuWrapper.find('.item-close'),
        $menuLi             = $menuWrapper.find('ul li'),
        $pageLoader       = $('.page-loader')
    ;

    let controller = new ScrollMagic.Controller();
    let scenes = [];



    const $lazyRessources = $('[data-src]');

    $.each($lazyRessources, function(){
        const $item = $(this);
        const src = $item.data('src');
        
        let scene = new ScrollMagic.Scene({
            'triggerElement': $item,
            'triggerHook': 1,
            'reverse': false
        })

        scene.on('enter', () => {
            console.log('loaded', src)

            if ($item.hasClass('lazy-bg')) {
                $item.css('backgroundImage', `url('${src}')`);
            }

            if ($item.hasClass('lazy-img')) {
                $item.attr('src', src)
            }

            $item.addClass('loaded');
        });

        scenes.push(scene)
    });


    controller.addScene(scenes);

    /*
  |
  | Loader
  |---------
  */
      const loaderTl = new TimelineMax({ paused: true });

      loaderTl.addCallback(() => { app.dispachEvent($body, 'loaderEnd'); })

      $(window).on('load', function () {
          loaderTl.play();
      });


      $body.on('loaderEnd', function(){
          $body.addClass('is-loaded')
          $body.removeClass('is-loading')
          initScroll()
      })


    /*
    |
    | initializations
    |------------------
    */
    window.locomotive = null;


    var
      controlup = new ScrollMagic.Controller({
        globalSceneOptions: {
          triggerHook: '1',
        }
      }),

      controlmiddle = new ScrollMagic.Controller({
        globalSceneOptions: {
          triggerHook: '0.5',
        }
      }),

      controldown = new ScrollMagic.Controller({
        globalSceneOptions: {
          triggerHook: '0',
        }
      });


      /*
      |
      | transition pages
      |-----------
      |
      */

      $('a.link-out').click(function(e) {
          e.preventDefault();
          var goTo = this.getAttribute("href");

          $('body').addClass('is-leaving');

          setTimeout(function() {
              window.location = goTo;
          }, 1000);
      });



function initScroll(){
  /*
  |
  | Parallax + Smooth scroll Locomotive
  |-----------------
  |
  */
  window.locomotive = new LocomotiveScroll({
    el: document.querySelector('.js-view'),
    smooth: true,
    getDirection: true,
  })

  window.locomotive.on('scroll', (instance) => {
    // if(!windowHasloaded || !windowHasloadedFired){
    //   scroll.update();
    //   console.log('updated')
    //   windowHasloadedFired = true;
    // }

    if($('#gform_submit_button_1')){
      $('#gform_submit_button_1').on('click', ()=>{
        window.locomotive.update();
      })
    }

    var
      scrolled = instance.scroll.y,
      lastScrollTop = 0,
      value = scrolled / 300 * -1,
      tl = new TimelineMax();



    /*
    |
    | Hide Header
    |-----------------
    |
    */
    if (scrolled > 20) {
      $("#header").addClass("hidden")

      /*
      |
      | Background header
      |-----------------
      |
       */
      if (instance.direction == 'up') {
        $("#header").removeClass("hidden")
        $("#header").addClass("is-on")
      } else {
        $("#header").addClass("hidden")
        $("#header").removeClass("is-on")
      }
    } else {
      $("#header").removeClass("hidden")
      $("#header").removeClass("is-on")
    }


    /*
    |
    | Rotate image
    |-----------------
    |
    */


    tl.staggerTo($(".is-inview .img-rotate"), 1.2, {
      rotation: value,
      ease: Power4.easeOut
    })

  });


  /*
  |
  | Splittext Titre
  |-----------------
  |
  // */
  $(".js-title").each(function(i) {

    var
      left = new SplitText($left_title[i], {
        type: "words,chars"
    }),
      right = new SplitText($right_title[i], {
        type: "words,chars"
      }),
      tl = new TimelineMax();

    tl.staggerFrom(left.chars, 1.2, {
      x: '100%',
      autoAlpha: 0,
      ease: Power4.easeOut
    }, 0.1, 0)
    tl.staggerFrom(right.chars, 1.2, {
      x: '-100%',
      autoAlpha: 0,
      ease: Power4.easeOut
    }, 0.1, 0)

    new ScrollMagic.Scene({
        triggerElement: this,
      })
      .setTween(tl)
      .addTo(controlup)
      .reverse(false);
  })

  /*
  |
  | Splittext Inside Titre + Paragraphe
  |-----------------
  |
  */
  $(".js-split").each(function(i) {
    var
      inside_title = new SplitText($inside_title[i], {
        type: "words,chars"
      }),
      inside_p = new SplitText($inside_p[i], {
        type: 'lines'
      }),
      tl = new TimelineMax();

    tl.staggerFrom(inside_title.chars, .8, {
      x: '100%',
      autoAlpha: 0,
      ease: Power4.easeOut
    }, 0.03, 0)
    tl.staggerFrom(inside_p.lines, 1, {
      y: '100%',
      autoAlpha: 0,
      ease: Power4.easeOut
    }, 0.1, .4)

    new ScrollMagic.Scene({
        triggerElement: this,
      })
      .setTween(tl)
      .addTo(controlup)
      .reverse(false);
  })

  /*
  |
  | Splittext Inside Titre seul
  |-----------------
  |
  */
  $(".js-alone").each(function(i) {
    var
      inside_title_alone = new SplitText($inside_title_alone[i], {
        type: "words,chars"
      }),
      tl = new TimelineMax();

    tl.staggerFrom(inside_title_alone.chars, .8, {
      x: '100%',
      autoAlpha: 0,
      ease: Power4.easeOut
    }, 0.03, 0)

    new ScrollMagic.Scene({
        triggerElement: this,
      })
      .setTween(tl)
      .addTo(controlup)
      .reverse(false);
  });

  var tl = new TimelineMax({
    paused: true,
    delay: 0.5
  })

  $('.svg-stagger').each(function() {
    const $this = $(this)
    const text = new SplitText($this.find('p'), {
      type: "words,chars"
    });

    tl.staggerFrom($this.find('svg'), 0.8, {
      autoAlpha: 0,
      y: -20,
      ease: Power4.easeOut
    }, 0.4 )

    tl.staggerFrom(text.chars, 0.1, {
      x: '100%',
      autoAlpha: 0,
      ease: Power4.easeOut
    }, 0.03, '-=0.8')
  })

  tl.play()

// });

}




    /*
    |
    | Menu
    |-------
    */
    const menu = new Menu($menuWrapper, $menuButton, {
        reverseTimeScale: 2
    });

    menu.menuTimeline
        .to($menuBars.eq(1), 0.3, { autoAlpha: 0 }, 'start')
        .to($menuBars.eq(0), 0.5, { x: 0, y: 8, rotation: 45, ease: Power1.easeOut }, 'start')
        .to($menuBars.eq(2), 0.5, { x: 0, y: -8, rotation: -45, ease: Power1.easeOut }, 'start')
        .to($menuWrapper, 0.5, { autoAlpha: 1, ease: Power1.easeOut }, 'start+=0.3')
        .staggerFrom($menuLi, 1, { opacity: 0, y: 60, ease: 'easeCustomPower1' }, 0.1, '-=0.7')

    menu.init();

    $menuWrapper.on('menu:open', () => {
        $menuWrapper.addClass('active');
        $body.addClass('o-h');
        app.stopLocomotiveScroll();
    })

    $menuWrapper.on('menu:close', () => {
        $menuWrapper.removeClass('active');
        $body.removeClass('o-h');
        app.startLocomotiveScroll();
    })

  }
}

