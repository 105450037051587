/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (app, Plyr, Swiper) => {


    /*
    |
    | Constants
    |-----------
    */
    const infos = $('.product-info .item-title')
    if (infos) {
      infos.click(function() {
        const faq = this.closest('.product-info')
        $(faq).toggleClass('active')
      })

    }

    const player3 = new Plyr('#player3', {});
    player3.poster = $('.video-plyr').attr('data-poster')

    const galleryThumbs = new Swiper('.gallery-thumbs', {
      spaceBetween: 10,
      slidesPerView: $('.gallery-thumbs .swiper-slide').length,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
    });

    const galleryTop = new Swiper('.gallery-top', {
      spaceBetween: 10,
      thumbs: {
        swiper: galleryThumbs,
				slideThumbActiveClass: 'swiper-slide-thumb-active'
      },

    });

  }
}

