/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
  init: (app, AjaxZone, ScrollMagic) => {
    /*
    |
    | Constants
    |-----------
    */
    const
        $blocFilters = $('.bloc-filter'),
        $itemFilter = $('.item-filter'),
        $removeFilters = $('.remove-filter')
    ;

    const item = $('.filters h4')
    if (item) {
      item.click(function() {
        const block = this.closest('.filters')
        $(block).toggleClass('open')
      })

    }


    /*
    |
    | Variables
    |-----------
    */
    let
        state = {};
    ;


    /*
    |
    | generate state object
    |-----------------------
    */
    $.each($blocFilters, function(){
        const $bloc = $(this);
        const taxonomy = $bloc.data('taxonomy');

        state[taxonomy] = [];
    });


    /*
    |
    | Filter
    |-----------
    */
    $itemFilter.on('click', function(e){
        e.preventDefault();

        const $filter = $(this);
        const $bloc = $filter.closest('.bloc-filter');
        const taxonomy = $bloc.data('taxonomy');
        const slug = $filter.data('slug');

        $filter.toggleClass('active');

        if($filter.hasClass('active')){
          state[taxonomy].push(slug);
        } else {
          state[taxonomy] = state[taxonomy].filter(value => value != slug)
        }

        filterProducts();
    });

    /*
    |
    | Remove Filters
    |-----------------
    */
    $removeFilters.on('click', e => {
        e.preventDefault();

        for (let key in state) {
            state[key] = [];
        }

        $itemFilter.removeClass('active');
        filterProducts();
    });


    /*
    |
    | Ajax
    |--------
    */
    const ajaxZone = new AjaxZone({
        loader: '.products-loader',
        container: '.products-container'
    });

    

    /*
    |
    | filterProducts
    |----------------
    */
    function filterProducts(){
      const route = '/ajax/products/filtered';

      ajaxZone.filter(route, {
        datas: {
          filters: state
        }
      }).then((response) => {
          app.updateLocomotiveScroll('#products-zone');

          let controller = new ScrollMagic.Controller();
          let scenes = [];

          const $lazyRessources = $('[data-src]');

          $.each($lazyRessources, function(){
              const $item = $(this);
              const src = $item.data('src');
              
              let scene = new ScrollMagic.Scene({
                  'triggerElement': $item,
                  'triggerHook': 1,
                  'reverse': false
              })

              scene.on('enter', () => {
                  console.log('loaded', src)

                  if ($item.hasClass('lazy-bg')) {
                      $item.css('backgroundImage', `url('${src}')`);
                  }

                  if ($item.hasClass('lazy-img')) {
                      $item.attr('src', src)
                  }

                  $item.addClass('loaded');
              });

              scenes.push(scene)
          });


          controller.addScene(scenes);


      })
    }


    $('.products a').on('click', function(e){
      const target = e.target
      if($(target).hasClass('link-ex')){
        e.preventDefault()
      }
    })


  }
}

