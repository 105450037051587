/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, Plyr) => {

		const player = new Plyr('#player', {
			playsinline: true,
			loop: {
				active: true,
			},
			ratio: '16:9',
			youtube:{
				controls: 0,
				rel: 0,
				showinfo: 0,
				autoplay: 1,
				loop: true,
				muted : true,
			},
		});

		const player3 = new Plyr('#player3', {});
		player3.poster = $('.video-plyr').attr('data-poster')
	}
}

